<template>
  <div class="flight_box_three">
    <!-- <div class="flight_box_three_body_one">
      <div class="d-flex justify-content-between">
        <h3>{{ $t("flight-only.prices-and-flight") }}</h3>
        <p :style="lang !== 'he'? 'direction: ltr;': ''">
          <span>{{ $t("flight-only.product-message1")}}</span><br />
          <span>{{ $t("flight-only.product-message2")}}</span>
        </p>
      </div>
    </div> -->
    <BoxThreeChild
      :airlineNotConfirmed="airlineNotConfirmed"
      :image="airlineLogo"
      :total="totalPrice"
      :average="averagePrice"
      :infoFlight="infoFlight"
      :currencySymbol="product.cc"
      :occupancy="occupancy"
      @emitBook="booking"
      @emitUpdatePax="updatePax"
    />
    <!-- <BoxThreeChild image="bulgeri" /> -->
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import googleAnalytics from '@/utils/googleAnalytics';
import imageUrlMixin from '@/utils/imageUrlMixin';
import mixins from '@/utils/mixins';

const { VUE_APP_CHANNEL_MOBILE_APP } = process.env;

export default {
  components: {
    BoxThreeChild: () => import('@/components/bookingFlightOnly/FlightBoxThreeChild'),
  },
  mixins: [googleAnalytics, imageUrlMixin, mixins],
  props: {
    product: {
      type: Object,
      default: null,
    },
    airInfo: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      query: null,
      adultPrice: 0,
      childPrice: 0,
      infantPrice: 0,
      airlineLogo: '',
      airlineNotConfirmed: false,

      totalPrice: 0,
      averagePrice: 0,
    };
  },
  watch: {
    airInfo() {
      this.airlineLogo = this.airInfo.desc2 || `${this.speedSizeDomain}/assets/img/blueair.jpg`;
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      marketerId: 'GET_MARKETER_ID',
      typeChannel: 'GET_TYPE_CHANNEL',
      isMobileApp: 'GET_STATE_MOBILE_APP',
    }),
    infoFlight: {
      get() {
        // let description = this.product.flights[0].FlightDetail[0].Authorization_msg;
        // if (!description.includes('אושרה')) description = 'פרטי הטיסה, שעת הטיסה ומספר הטיסה לא אושרו על ידי מנהל התעופה ועל כן לא פורטו. עם אישורם תעודכנו';
        return {
          name: this.product?.flights?.[0]?.FlightDetail?.[0].arl_Name,
          description: this.product?.flights?.[0]?.FlightDetail?.[0]?.Authorization_msg,
        };
      },
    },
    countAdult: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.adult
          ? this.$store.getters.GET_SEARCH_CONTENT.adult
          : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['adult', value]);
      },
    },
    countChild: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.child ? this.$store.getters.GET_SEARCH_CONTENT.child : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['child', value]);
      },
    },
    countInfant: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.infant ? this.$store.getters.GET_SEARCH_CONTENT.infant : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['infant', value]);
      },
    },
    occupancy() {
      return {
        adult: this.countAdult,
        child: this.countChild,
        infant: this.countInfant,
        price: this.getFlightPrice('adult'),
        priceChild: this.getFlightPrice('child'),
        priceInf: this.getFlightPrice('infant'),
        totalPrice: this.totalPrice,
        cc: this.product.cc,
      };
    },
  },
  created() {
    this.airlineLogo = this.airInfo?.desc2 || `${this.speedSizeDomain}/assets/img/blueair.jpg`;
    this.airlineNotConfirmed = this.product?.flights?.[0]?.FlightDetail[0].airlineNotConfirmed;
  },
  beforeMount() {
    this.query = this.$route.query;
    this.countAdult = Number(this.query.adult);
    this.countChild = Number(this.query.child);
    this.countInfant = Number(this.query.infant);

    this.updatePax();
    this.averagePrice = this.totalPrice / (Number(this.query.adult) + Number(this.query.child));
  },
  methods: {
    async booking() {
      this.product.packageId = Number(this.product.packId);
      this.product.duration = Number(this.product.duration_1);
      // this.product.discountPercent = this.product.discountPercent || null;
      this.product.price = Number(this.product.priceTotal);
      this.product.priceNIS = 0;

      const data = {
        data: this.product,
        sendingStage: 'init',
      };
      const response = await this.$store.dispatch('SEND_BOOKING_DATA', data);
      if (response.success) {
        this.product.priceNIS = response.data.priceNis;
        window.sessionStorage.setItem('production', JSON.stringify(this.product));
        window.sessionStorage.setItem('BookingDataID', response.data.bookingTransactionId);
        window.sessionStorage.setItem('retryState', 'null');
      } else {
        window.sessionStorage.setItem('BookingDataID', '');
      }

      if (this.availableEmitEventGA4ByDomain()) {
        const flight = this.product.flights[0].FlightDetail[0],
          destCode = flight?.FL_To_Route,
          destName = flight?.translations?.flightDestinationName?.[destCode].he || flight?.translations?.flightDestinationName?.[destCode].en;
        const currencyCode = this.convertCurrencyCode(this.product.cc);
        const affiliation = this.isMobileApp ? 'BO NAUF' : 'Flying Carpet';
        // const checkInOut = `${flight.FL_Date} - ${flight.landingDate}`;
        this.gtag('event', 'view_item', {
          // items: [
          //   {
          //     id: response.data.bookingTransactionId, // please assign number
          //     name: `${flight.arl_Name} - ${destName}`,
          //     list_name: '',
          //     brand: '',
          //     category: '',
          //     variant: '',
          //     list_position: 1,
          //     quantity: 1,
          //     price: this.totalPrice,
          //   },
          // ],

          currency: currencyCode,
          value: this.totalPrice,
          items: [
            {
              item_id: response.data.bookingTransactionId,
              item_name: `${flight.arl_Name} - ${destName}`,
              affiliation,
              coupon: '',
              discount: '',
              index: 0,
              item_brand: '',
              item_category: destName,
              item_category2: this.getCheckInOut(),
              item_category3: '',
              item_category4: '',
              item_category5: '',
              item_list_id: '',
              item_list_name: '',
              item_variant: '',
              location_id: '',
              price: this.totalPrice,
              quantity: 0,
            },
          ],
        });
      }
      const activeFlightId = `${this.product.flights[0].FlightDetail[0]?.FL_ID}${this.product.flights[0].FlightDetail[1]?.FL_ID}`;
      const body = {
        packID: this.query.packId,
        flights: activeFlightId,
        channel: this.query.channel,
      };
      if (this.marketerId !== '') body.marketerId = this.marketerId;
      let path = '/booking/flight/web';
      if (this.typeChannel === VUE_APP_CHANNEL_MOBILE_APP) {
        path = '/booking/flight/oldapp';
      }
      this.$router.push({ path, query: body });
    },
    updatePax() {
      this.product.generationInfo = {
        adult: this.countAdult,
        child: this.countChild,
        infant: this.countInfant,
      };
      this.calcTotalPrice();
    },
    calcTotalPrice() {
      this.query = this.$route.query;
      this.adultPrice = this.getFlightPrice('adult') * Number(this.countAdult);
      this.childPrice = this.getFlightPrice('child') * Number(this.countChild);
      this.infantPrice = this.getFlightPrice('infant') * Number(this.countInfant);

      this.product.priceTotal = this.adultPrice + this.childPrice + this.infantPrice;
      this.totalPrice = this.adultPrice + this.childPrice + this.infantPrice;

      if (this.product.matchingDiscountRuleFO) {
        const originalAdultPrice = this.getOriginalPricePax('adult') * Number(this.countAdult),
          originalChildPrice = this.getOriginalPricePax('child') * Number(this.countChild),
          originalInfantPrice = this.getOriginalPricePax('infant') * Number(this.countInfant);
        if (this.adultPrice === originalAdultPrice) {
          this.product.discountPercent = null;
        } else {
          const totalUndiscountedPrice = originalAdultPrice + originalChildPrice + originalInfantPrice;
          this.product.discountPercent = ((totalUndiscountedPrice - this.totalPrice) * 100) / totalUndiscountedPrice;
        }
      } else {
        this.product.discountPercent = null;
      }

      window.sessionStorage.setItem('production', JSON.stringify(this.product));
    },
    getFlightPrice(person) {
      let ageFrom = 0,
        ageTo = 0;
      if (person === 'child') {
        ageFrom = 0;
        ageTo = 0;
      }
      if (person === 'infant') {
        ageFrom = 0;
        ageTo = 2;
      }
      return this.product?.flightOnlyPrice?.find((item) => item.age_from === ageFrom && item.age_to === ageTo).price;
    },
    getOriginalPricePax(person) {
      let ageFrom = 0,
        ageTo = 0;
      if (person === 'child') {
        ageFrom = 0;
        ageTo = 0;
      }
      if (person === 'infant') {
        ageFrom = 0;
        ageTo = 2;
      }
      return this.product?.originalPricesFO?.priceByAge.find((item) => item.age_from === ageFrom && item.age_to === ageTo).price;
    },
  },
};
</script>
